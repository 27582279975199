import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function init() {
  gsap.registerPlugin(ScrollTrigger)
  window.addEventListener('load', () => {
    document.body.classList.add('loaded')

    animationsInit()
    hover3dInit()
  })

  window.addEventListener('beforeunload', () => {
    // document.body.classList.remove('loaded')
  })
}

const animations: Record<string, gsap.TweenVars> = {
  ['fade-in-up']: {
    opacity: 0,
    y: 100,
  },
  ['fade-in-left']: {
    opacity: 0,
    x: -100,
  },
  ['fade-in-right']: {
    opacity: 0,
    x: 100,
  },
}

function animationsInit() {
  const startedScrollTrigger = {
    trigger: '.started',
    scrub: true,
    start: 'top top',
    end: 'bottom top',
  }

  gsap.to('.started-background img', {
    scale: 1.2,
    y: 100,
    scrollTrigger: {
      ...startedScrollTrigger,
      scrub: 2,
    },
  })

  gsap.fromTo(
    '.about-background',
    { y: -100 },
    {
      y: 100,
      scrollTrigger: {
        trigger: '.about',
        scrub: 1,
        start: 'top bottom',
        end: 'bottom top',
      },
    }
  )

  const items = document.querySelectorAll('[data-animation]')

  items.forEach((item) => {
    const key = item.getAttribute('data-animation')

    if (!key) return

    gsap.from(item, {
      ...animations[key],
      ease: 'elastic(1,1)',
      scrollTrigger: {
        trigger: item,
        start: 'top 90%',
        end: 'bottom 10%',
      },
    })
  })
}

function hover3dInit() {
  const limits = 15.0
  const cards = document.querySelectorAll('.hover3d-wrap')

  cards.forEach((card) => {
    const item = card.querySelector('.hover3d-target')

    card.addEventListener('mousemove', (e) => {
      const event = e as MouseEvent
      const rect = (event.target as HTMLElement)?.getBoundingClientRect()
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top
      const offsetX = x / rect.width
      const offsetY = y / rect.height

      const rotateY = offsetX * (limits * 2) - limits
      const rotateX = offsetY * (limits * 2) - limits

      gsap.to(item, {
        rotateX: -rotateX,
        rotateY: rotateY,
      })
    })

    card.addEventListener('mouseleave', () => {
      gsap.to(item, {
        rotateX: 0,
        rotateY: 0,
      })
    })
  })
}

export default { init }
